var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-navbar"
  }, [_c('div', {
    staticClass: "cyui-navbar-body"
  }, [_c('van-tabs', {
    ref: "tab",
    attrs: {
      "swipe-threshold": _vm.swipeThreshold,
      "lazy-render": _vm.lazyRender
    },
    on: {
      "click": _vm.handleTabClick,
      "change": _vm.handleTabChange,
      "disabled": _vm.handleTabDisabled,
      "rendered": _vm.handleTabRendered,
      "scroll": _vm.handleTabScroll
    },
    model: {
      value: _vm.tabValue,
      callback: function ($$v) {
        _vm.tabValue = $$v;
      },
      expression: "tabValue"
    }
  }, _vm._l(_vm.listData, function (item, index) {
    return _c('van-tab', {
      key: `tab${index}`,
      attrs: {
        "name": item[_vm.props.id],
        "disabled": !!item[_vm.props.disabled]
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_vm._t("title", function () {
            return [_c('div', {
              staticClass: "cyui-navbar-title cyui-badge"
            }, [_vm._t("titleIcon", function () {
              return [_vm.showTitleIcon ? _c('div', {
                staticClass: "cyui-navbar-title-icon"
              }, [_c('img', {
                attrs: {
                  "src": item[_vm.props.icon],
                  "alt": ""
                }
              })]) : _vm._e()];
            }, {
              "item": item,
              "icon": item[_vm.props.icon]
            }), _vm._t("titleText", function () {
              return [_c('div', {
                staticClass: "cyui-navbar-title-text"
              }, [_vm._v(_vm._s(item[_vm.props.label]))])];
            }, {
              "title": item[_vm.props.title]
            }), _vm._t("titleNumber", function () {
              return [_vm.showTitleNumber && item[_vm.props.number] > 0 ? _c('div', {
                staticClass: "cyui-navbar-title-number"
              }, [_vm._v(" " + _vm._s(item[_vm.props.number]) + " ")]) : _vm._e()];
            }, {
              "item": item,
              "number": item[_vm.props.number]
            }), _vm._t("titleDot", function () {
              return [_vm.showTitleDot && item[_vm.props.dot] ? _c('span', {
                staticClass: "cyui-badge-dot"
              }) : _vm._e()];
            }, {
              "item": item
            })], 2)];
          }, {
            "item": item
          })];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1), _vm.showNavbarRight ? _c('div', {
    staticClass: "cyui-navbar-right"
  }, [_vm._t("right")], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };