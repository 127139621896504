import { appTitle } from '@config/config';
export default {
  name: 'Header',
  mixins: [],
  components: {},
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: appTitle
    }
  },
  computed: {},
  methods: {
    goBack() {
      // 回退
      this.$emit('go-back');
    },
    handleClosePage() {
      // 关闭页面
      this.$emit('on-close');
    },
    async initData() {}
  },
  watch: {},
  created() {
    this.initData();
  },
  mounted() {}
};