export default {
  name: 'FieldListInfo',
  mixins: [],
  components: {},
  data() {
    return {};
  },
  props: {
    fields: {
      type: Array,
      default() {
        return [];
      }
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    titleWidth: {
      type: String,
      default: 'auto'
    }
  },
  computed: {},
  methods: {
    // 验证是否为空
    isEmpty(data) {
      return !(data && data !== 'null' && data !== 'undefined');
    }
  },
  watch: {},
  created() {},
  mounted() {}
};