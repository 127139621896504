import Layout from '@views/layout/layout';
const assetsRouter = {
  path: '/assets',
  component: Layout,
  name: 'Assets',
  redirect: '/assets/index',
  meta: {
    title: '物资管理',
    noCache: true
  },
  children: [{
    path: 'index',
    name: 'AssetsIndex',
    components: {
      default: () => import('@views/assets/index')
    },
    meta: {
      title: '物资管理',
      noCache: true
    }
  }, {
    path: 'in',
    name: 'AssetsIn',
    components: {
      default: () => import('@views/assets/in')
    },
    meta: {
      title: '入库',
      noCache: true
    }
  }, {
    path: 'inDetail',
    name: 'AssetsInDetail',
    components: {
      default: () => import('@views/assets/inDetail')
    },
    meta: {
      title: '采购入库',
      noCache: true
    }
  }, {
    path: 'inDetail2',
    name: 'AssetsInDetail2',
    components: {
      default: () => import('@views/assets/inDetail2')
    },
    meta: {
      title: '调拨入库',
      noCache: true
    }
  }, {
    path: 'inSuccess',
    name: 'AssetsInSuccess',
    components: {
      default: () => import('@views/assets/inSuccess')
    },
    meta: {
      title: '',
      noCache: true
    }
  }, {
    path: 'inLog',
    name: 'AssetsInLog',
    components: {
      default: () => import('@views/assets/inLog')
    },
    meta: {
      title: '入库记录',
      noCache: true
    }
  }, {
    path: 'inLogDetail',
    name: 'AssetsInLogDetail',
    components: {
      default: () => import('@views/assets/inLogDetail')
    },
    meta: {
      title: '采购入库',
      noCache: true
    }
  }, {
    path: 'inLogDetail2',
    name: 'AssetsInLogDetail2',
    components: {
      default: () => import('@views/assets/inLogDetail2')
    },
    meta: {
      title: '调拨入库',
      noCache: true
    }
  }, {
    path: 'checkSelect',
    name: 'AssetsCheckSelect',
    components: {
      default: () => import('@views/assets/checkSelect')
    },
    meta: {
      title: '盘点',
      noCache: true
    }
  }, {
    path: 'checkIndex',
    name: 'AssetsCheckIndex',
    components: {
      default: () => import('@views/assets/checkIndex')
    },
    meta: {
      title: '盘点',
      noCache: true
    }
  }, {
    path: 'checkLog',
    name: 'AssetsCheckLog',
    components: {
      default: () => import('@views/assets/checkLog')
    },
    meta: {
      title: '盘点记录',
      noCache: true
    }
  }, {
    path: 'checkLogDetail',
    name: 'AssetsCheckLogDetail',
    components: {
      default: () => import('@views/assets/checkLogDetail')
    },
    meta: {
      title: '盘点',
      noCache: true
    }
  }, {
    path: 'checkSuccess',
    name: 'AssetsCheckSuccess',
    components: {
      default: () => import('@views/assets/checkSuccess')
    },
    meta: {
      title: '盘点',
      noCache: true
    }
  }, {
    path: 'take',
    name: 'AssetsTake',
    components: {
      default: () => import('@views/assets/take')
    },
    meta: {
      title: '申请领用',
      noCache: true
    }
  }, {
    path: 'takeSuccess',
    name: 'AssetsTakeSuccess',
    components: {
      default: () => import('@views/assets/takeSuccess')
    },
    meta: {
      title: '申请领用',
      noCache: true
    }
  }, {
    path: 'takeLog',
    name: 'AssetsTakeLog',
    components: {
      default: () => import('@views/assets/takeLog')
    },
    meta: {
      title: '申领记录',
      noCache: true
    }
  }, {
    path: 'takeLogDetail',
    name: 'AssetsTakeLogDetail',
    components: {
      default: () => import('@views/assets/takeLogDetail')
    },
    meta: {
      title: '申请领用',
      noCache: true
    }
  }, {
    path: 'dispose',
    name: 'AssetsDispose',
    components: {
      default: () => import('@views/assets/dispose')
    },
    meta: {
      title: '处置',
      noCache: true
    }
  }, {
    path: 'disposeSuccess',
    name: 'AssetsDisposeSuccess',
    components: {
      default: () => import('@views/assets/disposeSuccess')
    },
    meta: {
      title: '处置',
      noCache: true
    }
  }, {
    path: 'disposeLog',
    name: 'AssetsDisposeLog',
    components: {
      default: () => import('@views/assets/disposeLog')
    },
    meta: {
      title: '处置记录',
      noCache: true
    }
  }, {
    path: 'disposeLogDetail',
    name: 'AssetsDisposeLogDetail',
    components: {
      default: () => import('@views/assets/disposeLogDetail')
    },
    meta: {
      title: '处置',
      noCache: true
    }
  }, {
    path: 'dispatch',
    name: 'AssetsDispatch',
    components: {
      default: () => import('@views/assets/dispatch')
    },
    meta: {
      title: '调拨',
      noCache: true
    }
  }, {
    path: 'dispatchApply',
    name: 'AssetsDispatchApply',
    components: {
      default: () => import('@views/assets/dispatchApply')
    },
    meta: {
      title: '调拨申请',
      noCache: true
    }
  }, {
    path: 'dispatchApplySuccess',
    name: 'AssetsDispatchApplySuccess',
    components: {
      default: () => import('@views/assets/dispatchApplySuccess')
    },
    meta: {
      title: '申请成功',
      noCache: true
    }
  }, {
    path: 'dispatchApplyLogCommit',
    name: 'AssetsDispatchApplyLogCommit',
    components: {
      default: () => import('@views/assets/dispatchApplyLogCommit')
    },
    meta: {
      title: '我发起的申请记录',
      noCache: true
    }
  }, {
    path: 'dispatchApplyLogReceive',
    name: 'AssetsDispatchApplyLogReceive',
    components: {
      default: () => import('@views/assets/dispatchApplyLogReceive')
    },
    meta: {
      title: '向我发起的申请记录',
      noCache: true
    }
  }, {
    path: 'dispatchApplyDetail',
    name: 'AssetsDispatchApplyDetail',
    components: {
      default: () => import('@views/assets/dispatchApplyDetail')
    },
    meta: {
      title: '调拨申请',
      noCache: true
    }
  }, {
    path: 'dispatchOut',
    name: 'AssetsDispatchOut',
    components: {
      default: () => import('@views/assets/dispatchOut')
    },
    meta: {
      title: '调拨出库',
      noCache: true
    }
  }, {
    path: 'dispatchOutDetail',
    name: 'AssetsDispatchOutDetail',
    components: {
      default: () => import('@views/assets/dispatchOutDetail')
    },
    meta: {
      title: '调拨出库',
      noCache: true
    }
  }, {
    path: 'dispatchOutSuccess',
    name: 'AssetsDispatchOutSuccess',
    components: {
      default: () => import('@views/assets/dispatchOutSuccess')
    },
    meta: {
      title: '调拨出库',
      noCache: true
    }
  }, {
    path: 'dispatchOutLog',
    name: 'AssetsDispatchOutLog',
    components: {
      default: () => import('@views/assets/dispatchOutLog')
    },
    meta: {
      title: '出库记录',
      noCache: true
    }
  }, {
    path: 'dispatchOutLogDetail',
    name: 'AssetsDispatchOutLogDetail',
    components: {
      default: () => import('@views/assets/dispatchOutLogDetail')
    },
    meta: {
      title: '调拨出库',
      noCache: true
    }
  }, {
    path: 'add',
    name: 'AssetsAdd',
    components: {
      default: () => import('@views/assets/add')
    },
    meta: {
      title: '添加物资',
      noCache: true
    }
  }, {
    path: 'add2',
    name: 'AssetsAdd2',
    components: {
      default: () => import('@views/assets/add2')
    },
    meta: {
      title: '添加房内资产',
      noCache: true
    }
  }]
};
export default assetsRouter;