import defaultImage from '../assets/logo.png';
import lazyloadImage from '../assets/images/lazyload.png';

/**
 * Prefix
 * @type {string}
 */
export const PREFIX = 'Cyui';

/**
 * 项目Version 1.0.1
 * @type {string}
 */
export const versionName = process.env.VUE_APP_VERSION_NAME;

/**
 * 是否自定义头部
 * @type {boolean}
 */
export const isCustomHeader = process.env.VUE_APP_HEADER !== 'false';

/**
 * 项目Title
 * @type {string}
 */
export const appTitle = process.env.VUE_APP_TITLE;

/**
 * 是否本地开发环境
 * @type {boolean}
 */
export const isDevelopment = process.env.NODE_ENV === 'development';

/**
 * 是否正式生产环境
 * @type {boolean}
 */
export const isProduction = process.env.NODE_ENV === 'production';

/**
 * 配置API根模块
 * @type {string}
 */
export const baseAPIModule = process.env.VUE_APP_API_MODULE;

/**
 * 配置API根模块2（工单）
 * @type {string}
 */
export const baseAPIModuleGD = process.env.VUE_APP_API_MODULE_GD;

/**
 * 配置API根模块（APP）
 * @type {string}
 */
export const baseAPIModuleAPP = process.env.VUE_APP_API_MODULE_APP;

/**
 * 配置API根模块3（支付）
 * @type {string}
 */
export const baseAPIModulePAY = process.env.VUE_APP_API_MODULE_PAY;

/**
 * 配置API根域名
 * @type {string}
 */
export const baseAPIPath = process.env.VUE_APP_API_PATH;

/**
 * 配置API根域名2
 * @type {string}
 */
export const baseAPIPathGD = process.env.VUE_APP_API_PATH_GD;

/**
 * 配置API根域名(App）
 * @type {string}
 */
export const baseAPIPathAPP = process.env.VUE_APP_API_PATH_APP;

/**
 * 配置API根域名3（支付）
 * @type {string}
 */
export const baseAPIPathPAY = process.env.VUE_APP_API_PATH_PAY;

/**
 * 配置图片根域名
 * @type {string}
 */
export const basePicPath = process.env.VUE_APP_PIC_PATH;

/**
 * 默认图片
 * @type {string}
 */
export const defaultPic = defaultImage;

/**
 * 懒加载图片
 * @type {string}
 */
export const lazyloadPic = lazyloadImage;

/**
 * 中断请求提示文案
 * @type {string}
 */
export const cancelAxiosText = '前端处理，请求中断!!!';