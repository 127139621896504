import "core-js/modules/es.array.push.js";
import * as types from './mutations_types';
import { setStorage } from '@utils/storage';
export default {
  [types.SET_CACHED_VIEW](state, view) {
    if (state.cachedViews.includes(view.name)) return;
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name);
    }
  },
  [types.DELETE_CACHED_VIEW](state, view) {
    const index = state.cachedViews.indexOf(view.name);
    index > -1 && state.cachedViews.splice(index, 1);
  },
  [types.PUSH_ROUTE](state, {
    records,
    index,
    direction
  }) {
    state.records = records;
    state.index = index;
    state.direction = direction;
    setStorage('keep_alive_records', state.records);
    setStorage('keep_alive_index', state.index);
    setStorage('keep_alive_direction', state.direction);
  },
  [types.REPLACE_ROUTE](state, {
    records,
    index,
    direction
  }) {
    state.records = records;
    state.index = index;
    state.direction = direction;
    setStorage('keep_alive_records', state.records);
    setStorage('keep_alive_index', state.index);
    setStorage('keep_alive_direction', state.direction);
  },
  [types.POP_ROUTE](state, {
    records,
    index,
    direction
  }) {
    state.records = records;
    state.index = index;
    state.direction = direction;
    setStorage('keep_alive_records', state.records);
    setStorage('keep_alive_index', state.index);
    setStorage('keep_alive_direction', state.direction);
  }
};