import Layout from '@views/layout/layout';
const signContractRouter = {
  path: '/signContract',
  component: Layout,
  name: 'SignContract',
  redirect: '/signContract/list',
  meta: {
    title: '签约管理',
    noCache: true,
    requireAuth: true
  },
  children: [{
    path: 'list',
    name: 'SignContractList',
    components: {
      default: () => import('@views/signContract/list')
    },
    props: {
      default: route => ({
        status: route.query.status
      })
    },
    meta: {
      title: '签约管理',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'detail',
    name: 'SignContractDetail',
    components: {
      default: () => import('@views/signContract/detail')
    },
    props: {
      default: route => ({
        contractId: route.query.contractId
      })
    },
    meta: {
      title: '签约详情',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'offline',
    name: 'SignContractOffline',
    components: {
      default: () => import('@views/signContract/offline.vue')
    },
    props: {
      default: route => ({
        contractType: route.query.contractType
      })
    },
    meta: {
      title: '线下签约',
      noCache: true,
      requireAuth: true
    }
  }]
};
export default signContractRouter;