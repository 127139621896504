import "core-js/modules/es.error.cause.js";
import * as types from './mutations_types';
import { loginAPI } from '@/api/login';
export default {
  HandleLogin({
    commit
  }, userInfo) {
    // 登录请求数据
    return new Promise((resolve, reject) => {
      loginAPI({
        mobile: userInfo.phone,
        password: userInfo.password
      }).then(response => {
        // console.log(response)
        let result = response.data;
        if (result.code === '10000') {
          let projectListData = result.data.projects || [];
          let projectInfo = projectListData.length > 0 ? projectListData[0] : {};
          commit(types.SET_TOKEN, result.data.userInfo.token);
          commit(types.SET_USER_INFO, result.data.userInfo);
          commit(types.SET_PROJECT_LIST, projectListData);
          commit(types.SET_PROJECT_INFO, projectInfo);
          resolve(response);
        } else {
          reject(new Error(result.msg || '账号或者密码错误！'));
        }
      }).catch(error => {
        reject(error);
      });
    });
  },
  HandleLogout({
    commit,
    state
  }) {
    // 退出登录
    return new Promise((resolve, reject) => {
      commit(types.SET_TOKEN, '');
      commit(types.SET_USER_INFO, '');
      commit(types.SET_PROJECT_LIST, '');
      commit(types.SET_PROJECT_INFO, '');
      resolve();
    });
  },
  SetProjectInfo({
    commit,
    state
  }, data = {}) {
    // 更新项目信息
    return new Promise((resolve, reject) => {
      commit(types.SET_PROJECT_INFO, data);
      resolve();
    });
  }
};