var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "player",
    staticClass: "vue-xgplayer",
    attrs: {
      "id": _vm.id
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };