import { isProduction } from '@config/config';

/**
 * 接口请求错误回调
 * @param response
 */
export const errorCallback = response => {
  // console.log(response)
  let result = response.data;
  let returnInfo = {
    isError: false,
    errorCode: '',
    errorMessage: ''
  };
  switch (response.status) {
    case 401:
    case 403:
      returnInfo = {
        isError: true,
        errorCode: 401,
        errorMessage: `${isProduction ? '' : '尔家公寓错误：'}${response.data.msg || response.data.message || '无权访问！'}`
      };
      break;
    case 404:
    case 500:
    case 502:
      returnInfo = {
        isError: true,
        errorCode: 500,
        errorMessage: `${isProduction ? '' : '尔家公寓错误：'}${response.data.msg || response.data.message || '请求失败！'}`
      };
      break;
    default:
      // 判断是否需要全局拦截
      if (response.config.headers.INTERCEPT !== 'false') {
        if (`${result.code}` === '90022') {
          returnInfo = {
            isError: true,
            errorCode: 401,
            errorMessage: `${isProduction ? '' : '尔家公寓错误：'}${response.data.msg || response.data.message || '无权访问！'}`
          };
        } else {
          if (`${result.code}` !== '10000' && `${result.code}` != 200) {
            returnInfo = {
              isError: true,
              errorCode: 200,
              errorMessage: `${isProduction ? '' : '尔家公寓错误：'}${response.data.msg || response.data.message || '请求失败！'}`
            };
          }
        }
      }
  }
  return returnInfo;
};