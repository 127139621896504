var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-field-list"
  }, [_c('van-cell-group', [_vm._l(_vm.fields, function (item, index) {
    return [item.filterMethod ? [_c('van-cell', _vm._b({
      key: `baseInfo${index}`
    }, "van-cell", _vm._d({}, [item.type, item.filterMethod(`${_vm.data[item.key]}`) || '-'])), [item.icon ? _c('div', {
      staticClass: "cyui-field-icon",
      attrs: {
        "slot": "icon"
      },
      slot: "icon"
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": item.icon.svgName
      }
    })], 1) : _vm._e(), _c('div', {
      style: {
        width: _vm.titleWidth
      },
      attrs: {
        "slot": "title"
      },
      domProps: {
        "innerHTML": _vm._s(item.title)
      },
      slot: "title"
    })])] : [_c('van-cell', _vm._b({
      key: `baseInfo${index}`
    }, "van-cell", _vm._d({}, [item.type, !_vm.isEmpty(_vm.data[item.key]) ? _vm.data[item.key] : '-'])), [item.icon ? _c('div', {
      staticClass: "cyui-field-icon",
      attrs: {
        "slot": "icon"
      },
      slot: "icon"
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": item.icon.svgName
      }
    })], 1) : _vm._e(), _c('div', {
      style: {
        width: _vm.titleWidth
      },
      attrs: {
        "slot": "title"
      },
      domProps: {
        "innerHTML": _vm._s(item.title)
      },
      slot: "title"
    })])]];
  })], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };