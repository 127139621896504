import Layout from '@views/layout/layout';
const appointmentRouter = {
  path: '/appointment',
  component: Layout,
  name: 'Appointment',
  redirect: '/appointment/list',
  meta: {
    title: '预约记录',
    noCache: true,
    requireAuth: true
  },
  children: [{
    path: 'list',
    name: 'AppointmentList',
    components: {
      default: () => import('@views/appointment/list')
    },
    // props: {
    //   default: (route) => ({
    //     status: route.query.status
    //   })
    // },
    meta: {
      title: '预约记录',
      noCache: false,
      requireAuth: true
    }
  }, {
    path: 'detail',
    name: 'AppointmentDetail',
    components: {
      default: () => import('@views/appointment/detail')
    },
    props: {
      default: route => ({
        id: route.query.id
      })
    },
    meta: {
      title: '预约详情',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'apply',
    name: 'AppointmentApply',
    components: {
      default: () => import('@views/appointment/apply')
    },
    props: {
      default: route => ({
        id: route.query.id
      })
    },
    meta: {
      title: '接待登记',
      noCache: true,
      requireAuth: true
    }
  }]
};
export default appointmentRouter;