import router from '@router';
// import store from '@store'
import { Toast } from 'vant';
import { getStorage } from '@utils/storage.js';
import { getPageTitle, setDocumentTitle, queryToObj } from '@utils/index.js';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style

NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  // 开始进度条
  NProgress.start();
  const {
    title
  } = to.meta;
  // set page title
  // document.title = getPageTitle(to.meta.title)
  if (title && document.title !== title) setDocumentTitle(getPageTitle(to.meta.title));
  const hasToken = getStorage('token', 'localStorage'); // determine whether the user has logged in
  const {
    token
  } = queryToObj();
  if (token || hasToken) {
    // 存在token
    next();
    // if (to.path === '/login' || to.path === '/register') {
    //   if (to.query.redirect && to.query.redirect !== '/404') {
    //     next({ path: to.query.redirect })
    //   } else {
    //     next({ path: '/' })
    //   }
    //   NProgress.done()
    // } else {
    //   next()
    // }
  } else {
    // 没有token
    if (to.matched.some(r => r.meta.requireAuth)) {
      // 需要登录,跳转到登录页面
      next({
        name: 'Login',
        query: {
          redirect: to.fullPath
        }
      });
      // next()
      NProgress.done();
    } else {
      // 免登录，直接进入
      next();
    }
  }
});
router.afterEach((to, from) => {
  NProgress.done(); // 结束进度条
  Toast.clear(); // 清空所有弹出框
});