var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout"
  }, [_c('router-view', {
    attrs: {
      "name": "headerView"
    }
  }), _c('keep-alive', {
    attrs: {
      "include": _vm.keepAliveComponents
    }
  }, [_c('router-view', {
    key: _vm.key
  })], 1), _c('router-view', {
    attrs: {
      "name": "tabbarView"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };