import Layout from '@views/layout/layout';
const appointmentRouter = {
  path: '/directions',
  component: Layout,
  name: 'Directions',
  redirect: '/directions/list',
  meta: {
    title: '使用说明',
    noCache: true,
    requireAuth: true
  },
  children: [{
    path: 'list',
    name: 'DirectionsList',
    components: {
      default: () => import('@views/directions/list')
    },
    props: {
      default: route => ({
        status: route.query.status
      })
    },
    meta: {
      title: '使用说明',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'system',
    name: 'DirectionsSystem',
    components: {
      default: () => import('@views/directions/system')
    },
    props: {
      default: route => ({
        id: route.query.id
      })
    },
    meta: {
      title: '系统操作手册',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'qa',
    name: 'DirectionsQA',
    components: {
      default: () => import('@views/directions/QA')
    },
    props: {
      default: route => ({
        id: route.query.id
      })
    },
    meta: {
      title: 'Q&A手册',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'all-problem',
    name: 'DirectionsAllProblem',
    components: {
      default: () => import('@views/directions/all-problem')
    },
    // props: {
    //   default: (route) => ({
    //     id: route.query.id
    //   })
    // },
    meta: {
      title: '全部问题',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'category',
    name: 'DirectionsCategory',
    components: {
      default: () => import('@views/directions/category')
    },
    props: {
      default: route => ({
        id: route.query.id,
        name: route.query.name
      })
    },
    meta: {
      title: '全部问题分类',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'detail',
    name: 'DirectionsDetail',
    components: {
      default: () => import('@views/directions/detail')
    },
    props: {
      default: route => ({
        id: route.query.id
      })
    },
    meta: {
      title: '详情',
      noCache: true,
      requireAuth: true
    }
  }]
};
export default appointmentRouter;