var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout"
  }, [_c('div', {
    staticClass: "cyui-layout-header"
  }, [_vm._t("header")], 2), _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "cyui-layout-footer"
  }, [_vm._t("footer")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };