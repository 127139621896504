import filters from '@filters/filters';
export default {
  name: 'FieldRecordInfo',
  mixins: [filters],
  components: {},
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    headerContentField: {
      type: Object,
      default() {
        return {
          isShow: false,
          title: '',
          key: '',
          icon: {
            type: 'svg',
            svgName: ''
          },
          color: ''
        };
      }
    },
    headerExtraField: {
      type: Object,
      default() {
        return {
          isShow: false,
          title: '',
          key: '',
          icon: {
            type: 'svg',
            svgName: ''
          },
          color: ''
        };
      }
    },
    footerContentField: {
      type: Object,
      default() {
        return {
          isShow: false,
          title: '',
          key: '',
          icon: {
            type: 'svg',
            svgName: ''
          },
          color: ''
        };
      }
    },
    footerExtraField: {
      type: Object,
      default() {
        return {
          isShow: false,
          title: '',
          key: '',
          icon: {
            type: 'svg',
            svgName: ''
          },
          color: ''
        };
      }
    },
    bodyFields: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {},
  methods: {
    goDetail() {
      this.$emit('go-detail', this.data);
    },
    checkOut() {
      this.$emit('check-out', this.data);
    },
    async initData() {}
  },
  filters: {},
  watch: {},
  created() {
    this.initData();
  },
  mounted() {}
};