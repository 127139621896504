import Layout from '@views/layout/layout';
const houseRouter = {
  path: '/house',
  component: Layout,
  name: 'House',
  redirect: '/house/list',
  meta: {
    title: '房源查看',
    noCache: true,
    requireAuth: true
  },
  children: [{
    path: 'list',
    name: 'HouseList',
    components: {
      default: () => import('@views/house/list')
    },
    props: {
      default: route => ({
        // status: route.query.status
      })
    },
    meta: {
      title: '房源查看',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'epidemic',
    name: 'HouseEpidemic',
    components: {
      default: () => import('@views/house/epidemic')
    },
    props: {
      default: route => ({
        // status: route.query.status
      })
    },
    meta: {
      title: '防疫点房态图'
      // noCache: true,
      // requireAuth: true
    }
  }]
};

export default houseRouter;