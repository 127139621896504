var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "wrapper",
    staticClass: "scroll-wrapper"
  }, [_c('div', {
    staticClass: "scroll-content",
    style: _vm.scrollContentStyle
  }, [_c('div', [_vm._t("default")], 2), _vm._t("pulldown", function () {
    return [_vm.pullDownRefresh ? _c('div', {
      ref: "pulldown",
      staticClass: "pulldown-wrapper",
      style: _vm.pullDownStyle
    }, [_vm.pullDownInfo.beforePullDown ? _c('div', {
      staticClass: "before-trigger"
    }, [_c('div', {
      staticClass: "pulldown-content"
    }, [_c('div', {
      staticClass: "pulldown-progress",
      style: _vm.pullDownProgressStyle
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "spinner"
      }
    })], 1), _c('div', {
      staticClass: "pulldown-tips"
    }, [_vm._v(_vm._s(_vm.pullDownText))])])]) : _c('div', {
      staticClass: "after-trigger"
    }, [_vm.pullDownInfo.isLoadingRefresh ? _c('div', {
      staticClass: "pulldown-content"
    }, [_c('div', {
      staticClass: "pulldown-progress loading-rotate"
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "spinner"
      }
    })], 1), _c('div', {
      staticClass: "pulldown-tips"
    }, [_vm._v(_vm._s(_vm.pullDownText))])]) : _c('div', {
      staticClass: "pulldown-content"
    }, [_c('div', {
      staticClass: "pulldown-tips"
    }, [_vm._v(_vm._s(_vm.pullDownText))])])])]) : _vm._e()];
  }, {
    "pullDownRefresh": _vm.pullDownRefresh,
    "pullDownStyle": _vm.pullDownStyle,
    "beforePullDown": _vm.pullDownInfo.beforePullDown,
    "pulling": _vm.pullDownInfo.isLoadingRefresh
  }), _vm._t("pullup", function () {
    return [_vm.pullUpLoad ? _c('div', {
      staticClass: "pullup-wrapper"
    }, [!_vm.isPullUpLoad ? _c('div', {
      staticClass: "before-trigger"
    }, [_c('div', {
      staticClass: "pullup-content"
    }, [_c('div', {
      staticClass: "pullup-tips"
    }, [_vm._v(_vm._s(_vm.pullUpTxt))])])]) : _c('div', {
      staticClass: "after-trigger"
    }, [_c('div', {
      staticClass: "pullup-content"
    }, [_c('div', {
      staticClass: "pullup-progress loading-rotate"
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "spinner"
      }
    })], 1), _c('div', {
      staticClass: "pullup-tips"
    }, [_vm._v("加载中...")])])])]) : _vm._e()];
  }, {
    "pullUpLoad": _vm.pullUpLoad,
    "isPullUpLoad": _vm.isPullUpLoad
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };