import Layout from '@views/layout/layout'

const h5 = {
  path: '/h5',
  component: Layout,
  name: 'H5',
  children: [
    {
      path: 'rank/index',
      name: 'RankIndex',
      components: {
        default: () => import('@views/h5/ranking/index.vue')
      },
      meta: {
        title: '排行榜',
        noCache: true,
        requireAuth: false
      }
    }
  ]
}

export default h5
