import { isJSON } from '@/utils/index.js';
import { getStorage } from '@/utils/storage.js';
export default {
  token: getStorage('token', 'localStorage') || '',
  // token
  userInfo: isJSON(getStorage('user_info', 'localStorage')) ? JSON.parse(getStorage('user_info', 'localStorage')) : {},
  // 用户基本信息
  projectList: isJSON(getStorage('project_list', 'localStorage')) ? JSON.parse(getStorage('project_list', 'localStorage')) : [],
  // 项目列表
  projectInfo: isJSON(getStorage('project_info', 'localStorage')) ? JSON.parse(getStorage('project_info', 'localStorage')) : {} // 项目信息
};