export default {
  name: 'Navbar',
  mixins: [],
  components: {},
  data() {
    return {
      tabValue: ''
    };
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    listData: {
      type: Array,
      default() {
        return [];
      }
    },
    lazyRender: {
      type: Boolean,
      default: true
    },
    swipeThreshold: {
      type: [Number, String],
      default: 3
    },
    showTitleIcon: {
      type: Boolean,
      default: false
    },
    showTitleNumber: {
      type: Boolean,
      default: false
    },
    showTitleDot: {
      type: Boolean,
      default: false
    },
    showNavbarRight: {
      type: Boolean,
      default: false
    },
    props: {
      type: Object,
      default() {
        return {
          id: 'id',
          label: 'label',
          disabled: 'disabled',
          icon: 'icon',
          number: 'number',
          dot: false
        };
      }
    }
  },
  computed: {},
  methods: {
    handleTabClick(name, title) {
      this.$emit('click', {
        name,
        title
      });
    },
    handleTabChange(name, title) {
      this.$emit('change', {
        name,
        title
      });
    },
    handleTabDisabled(name, title) {
      this.$emit('disabled', {
        name,
        title
      });
    },
    handleTabRendered(name, title) {
      this.$emit('rendered', {
        name,
        title
      });
    },
    handleTabScroll({
      scrollTop,
      isFixed
    }) {
      this.$emit('scroll', {
        scrollTop,
        isFixed
      });
    },
    async initData() {}
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        if (newVal !== this.tabValue) {
          this.tabValue = newVal;
          this.$emit('input', newVal);
        }
      },
      immediate: true
    },
    listData: {
      handler: function (newVal, oldVal) {
        this.$refs.tab && this.$refs.tab.resize(); // 每次格式化列表数据后对tab栏目进行重绘
      },

      deep: true
    }
  },
  created() {
    this.initData();
  },
  mounted() {}
};