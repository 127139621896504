import Layout from '@views/layout/layout';
const checkOutRouter = {
  path: '/checkOut',
  component: Layout,
  name: 'CheckOut',
  redirect: '/checkOut/list',
  meta: {
    title: '退房',
    noCache: true,
    requireAuth: true
  },
  children: [{
    path: 'list',
    name: 'CheckOutList',
    components: {
      default: () => import('@views/checkOut/list')
    },
    props: {
      default: route => ({
        status: route.query.status
      })
    },
    meta: {
      title: '退房记录',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'detail',
    name: 'CheckOutDetail',
    components: {
      default: () => import('@views/checkOut/detail')
    },
    props: {
      default: route => ({
        id: route.query.id
      })
    },
    meta: {
      title: '退房详情',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'detailInfo',
    name: 'CheckOutDetailInfo',
    components: {
      default: () => import('@views/checkOut/detailInfo')
    },
    props: {
      default: route => ({
        id: route.params.id,
        type: route.params.type,
        detail: route.params.detail
      })
    },
    meta: {
      title: '退房信息',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'apply',
    name: 'CheckOutApply',
    components: {
      default: () => import('@views/checkOut/apply')
    },
    meta: {
      title: '代客退房申请',
      noCache: true,
      requireAuth: true
    }
  }]
};
export default checkOutRouter;