export default {
  name: 'Layout',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  watch: {},
  methods: {}
};