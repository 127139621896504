import BScroll from 'better-scroll';
import { formatPx2Rem } from '@utils/index';
const DIRECTION_H = 'horizontal';
const DIRECTION_V = 'vertical';
export default {
  name: 'Scroller',
  components: {},
  props: {
    probeType: {
      // 派发scroll事件的条件
      type: Number,
      default: 1
    },
    click: {
      // better-scroll 会派发一个 click 事件
      type: Boolean,
      default: true
    },
    listenScroll: {
      // 是否监听滚动，开启后才能Emit scroll事件
      type: Boolean,
      default: false
    },
    listenBeforeScroll: {
      // 是否监听滚动之前，开启后才能Emit beforeScrollStart事件
      type: Boolean,
      default: false
    },
    direction: {
      // 滚动方向
      type: String,
      default: DIRECTION_V
    },
    scrollbar: {
      type: null,
      default: false
    },
    pullDownRefresh: {
      // 下拉刷新——参数配置
      type: null,
      default: false
    },
    pullUpLoad: {
      type: null,
      default: false
    },
    startY: {
      // 纵轴方向初始化位置
      type: Number,
      default: 0
    },
    refreshDelay: {
      type: Number,
      default: 20
    },
    freeScroll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scrollContentStyle: {},
      pullDownInfo: {
        // 下拉刷新状态
        pullDownOffsetY: 0,
        // 下拉偏移距离
        pullDownStatus: 1,
        // 1、下拉刷新触发前 2、下拉刷新请求开始 3、下拉刷新请求结束 4、下拉刷新反弹开始 5、下拉刷新反弹结束
        beforePullDown: true,
        // 是否在下拉刷新前
        isTriggerRefresh: false,
        // 下拉刷新触发前，是否可以触发下拉刷新
        isPullingDown: false,
        // 是否在下拉刷新中 （包括数据请求、等待反弹）
        isLoadingRefresh: false,
        // 是否在下拉刷新数据请求中
        isRebounding: false // 是否在反弹中
      },

      pullDownRefreshThreshold: 90,
      pullDownProgressStyle: '',
      // 下拉图标样式
      isPullUpLoad: false,
      pullUpDirty: true,
      // 此变量用来检查是否加载到了最后一页
      pullDownStyle: '',
      bubbleY: 0,
      reboundPullDownTimer: null,
      afterPullDownTimer: null
    };
  },
  computed: {
    pullDownText() {
      // 下拉刷新-文案
      let {
        downText = '下拉刷新',
        upText = '释放更新',
        loadingText = '加载中 ...',
        resultText = '刷新成功'
      } = this.pullDownRefresh;
      let {
        beforePullDown,
        isTriggerRefresh,
        isPullingDown,
        isLoadingRefresh
      } = this.pullDownInfo;
      if (beforePullDown) {
        return isTriggerRefresh ? upText : downText;
      } else if (isPullingDown && isLoadingRefresh) {
        return loadingText;
      } else {
        return resultText;
      }
    },
    pullUpTxt() {
      const moreTxt = this.pullUpLoad && this.pullUpLoad.txt && this.pullUpLoad.txt.more || '加载更多';
      const noMoreTxt = this.pullUpLoad && this.pullUpLoad.txt && this.pullUpLoad.txt.noMore || '没有更多数据了';
      return this.pullUpDirty ? moreTxt : noMoreTxt;
    }
  },
  methods: {
    _resizeHandler() {
      this.$nextTick(() => {
        // this.destroyScroll()
        // this.initScroll()
        const thisWrapper = this.$refs.wrapper;
        thisWrapper.firstElementChild.firstElementChild.style.minHeight = thisWrapper.offsetHeight + 'px';
      });
    },
    destroyScroll() {
      // 销毁scroll
      this.scroll && this.scroll.destroy();
      this.scroll = null;
      this.reboundPullDownTimer && clearTimeout(this.reboundPullDownTimer);
      this.reboundPullDownTimer = null;
      this.afterPullDownTimer && clearTimeout(this.afterPullDownTimer);
      this.afterPullDownTimer = null;
    },
    getPullDownThreshold(threshold) {
      // 临时解决REM
      let htmlFontSize = parseFloat(document.getElementsByTagName('html')[0].style.fontSize); // 获取当前html的font-size
      let currentThreshold = htmlFontSize * formatPx2Rem(threshold);
      return currentThreshold;
    },
    initScroll() {
      if (!this.$refs.wrapper) {
        return;
      }
      // 重置下拉刷新的threshold
      let pullDownRefresh = JSON.parse(JSON.stringify(this.pullDownRefresh));
      if (pullDownRefresh.threshold) {
        pullDownRefresh.threshold = this.getPullDownThreshold(pullDownRefresh.threshold);
        this.pullDownRefreshThreshold = pullDownRefresh.threshold;
      }
      const options = {
        probeType: this.probeType,
        click: this.click,
        scrollY: this.freeScroll || this.direction === DIRECTION_V,
        scrollX: this.freeScroll || this.direction === DIRECTION_H,
        scrollbar: this.scrollbar,
        pullDownRefresh: pullDownRefresh,
        // 下拉刷新
        pullUpLoad: this.pullUpLoad,
        // 上拉加载更多
        startY: this.startY,
        freeScroll: this.freeScroll,
        bounceTime: 500
      };
      this.scroll = new BScroll(this.$refs.wrapper, options);
      if (this.listenScroll) {
        this.scroll.on('scroll', pos => {
          this.$emit('scroll', pos);
        });
      }
      if (this.listenBeforeScroll) {
        this.scroll.on('beforeScrollStart', () => {
          this.$emit('beforeScrollStart');
        });
      }
      if (this.pullDownRefresh) {
        this._initPullDownRefresh();
      }
      if (this.pullUpLoad) {
        this._initPullUpLoad();
      }
    },
    disable() {
      this.scroll && this.scroll.disable();
    },
    enable() {
      this.scroll && this.scroll.enable();
    },
    refresh() {
      this.scroll && this.scroll.refresh();
    },
    scrollTo() {
      this.scroll && this.scroll.scrollTo.apply(this.scroll, arguments);
    },
    scrollToElement() {
      this.scroll && this.scroll.scrollToElement.apply(this.scroll, arguments);
    },
    destroy() {
      this.scroll && this.scroll.destroy();
    },
    _initPullDownRefresh() {
      // 触发下拉刷新
      this.scroll.on('pullingDown', () => {
        let {
          stop
        } = this.pullDownRefresh;
        this.pullDownInfo.beforePullDown = false;
        this.pullDownInfo.isPullingDown = true;
        this.pullDownInfo.isLoadingRefresh = true;
        this.scrollContentStyle = {
          // 临时解决rem问题
          transform: `translateX(0px) translateY(${formatPx2Rem(stop)}rem) translateZ(0px)`
        };
        this.$emit('pullingDown');
      });
      this.scroll.on('scroll', pos => {
        let {
          pullDownRefreshThreshold
        } = this;
        this.pullDownInfo.pullDownOffsetY = pos.y;
        if (this.pullDownInfo.beforePullDown) {
          // 触发下拉刷新之前
          this.pullDownInfo.isTriggerRefresh = pos.y >= pullDownRefreshThreshold || false;
          this.pullDownProgressStyle = `transform: rotate(${pos.y * 2.5}deg)`;
          this.pullDownStyle = `top:0px`;
        } else {}
        if (this.pullDownInfo.isRebounding) {
          // console.log('+++')
          this.pullDownStyle = `top:0px`;
        }
      });
    },
    _initPullUpLoad() {
      this.scroll.on('pullingUp', e => {
        // console.log(11111)
        if (this.pullUpDirty) {
          this.isPullUpLoad = true;
          this.$emit('pullingUp');
        }
      });
    },
    forceUpdate(dirty) {
      // 加载数据后更新
      this.$nextTick(async () => {
        this.pullUpDirty = dirty;
        if (this.pullDownRefresh && this.pullDownInfo.isPullingDown) {
          // 数据请求后-下拉刷新的处理
          this.pullDownInfo.isLoadingRefresh = false;
          await this._reboundPullDown();
          await this._afterPullDown();
          this.refresh();
        } else if (this.pullUpLoad) {
          this.isPullUpLoad = false;
          this.scroll.finishPullUp();
          this.refresh();
        } else {
          this.refresh();
        }
      });
    },
    async _reboundPullDown() {
      // 下拉刷新-回弹
      let {
        stopTime = 500
      } = this.pullDownRefresh;
      return new Promise(resolve => {
        this.reboundPullDownTimer = setTimeout(() => {
          this.pullDownInfo.isPullingDown = false;
          this.pullDownInfo.isRebounding = true;
          this.scroll.finishPullUp();
          this.scroll.finishPullDown();
          resolve();
        }, stopTime);
      });
    },
    async _afterPullDown() {
      // 下拉刷新-结束
      let {
        bounceTime
      } = this.scroll.options;
      return new Promise(resolve => {
        this.afterPullDownTimer = setTimeout(() => {
          this.pullDownInfo.beforePullDown = true;
          this.pullDownInfo.isRebounding = false;
          this.scrollContentStyle = {
            transform: `translateX(0px) translateY(0px) translateZ(0px)`
          };
          this.pullDownStyle = `top:0px`;
          resolve();
        }, bounceTime);
      });
    }
  },
  created() {
    this.pullDownInitTop = -50;
  },
  beforeMount() {
    window.addEventListener('resize', this._resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this._resizeHandler);
  },
  mounted() {
    const thisWrapper = this.$refs.wrapper;
    thisWrapper.firstElementChild.firstElementChild.style.minHeight = thisWrapper.offsetHeight + 'px';
    this.$nextTick(() => {
      this.initScroll();
    });
  },
  destroyed() {
    this.destroyScroll();
  }
};