import Layout from '@views/layout/layout';
const ChongciRouter = {
  path: '/chongci',
  component: Layout,
  name: 'ChongciIndex',
  redirect: '/chongci/index',
  meta: {
    title: '百日冲刺',
    noCache: true,
    requireAuth: false,
    show: true
  },
  children: [{
    path: 'index',
    name: 'ChongciIndex',
    components: {
      default: () => import('@views/chongci/index')
    },
    meta: {
      title: '百日冲刺',
      noCache: true,
      requireAuth: false,
      show: true
    }
  }]
};
export default ChongciRouter;