import request from '@utils/request';
// import qs from 'qs'

/**
 * Organization-机构号查询
 * url:'/xdsp/getVideoCatalogTree'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{}
 * @returns {*}
 */
export const getOrganizationListAPI = (params = {}) => {
  return request.get('/xdsp/getVideoCatalogTree', {
    params
  });
};