var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('header', {
    staticClass: "cyui-header"
  }, [_c('div', {
    staticClass: "cyui-header-left"
  }, [_vm._t("headerLeft", function () {
    return [_c('span', {
      staticClass: "left-arrow",
      on: {
        "click": _vm.goBack
      }
    })];
  })], 2), _c('div', {
    staticClass: "cyui-header-title"
  }, [_vm._t("headerTitle", function () {
    return [_vm._v(_vm._s(_vm.title))];
  })], 2), _c('div', {
    staticClass: "cyui-header-right"
  }, [_vm._t("headerRight")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };