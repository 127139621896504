import * as types from './mutations_types';
export default {
  /**
   * 保存列表数据
   * @param commit
   * @param state
   * @param data
   * @constructor
   */
  SetAppointmentList({
    commit,
    state
  }, data = []) {
    return new Promise((resolve, reject) => {
      commit(types.SET_APPOINTMENT_LIST, data);
      resolve();
    });
  },
  /**
   * 根据ID获取详情数据
   * @param commit
   * @param state
   * @param params
   * @returns {Promise<unknown>}
   * @constructor
   */
  GetAppointmentDetail({
    commit,
    state
  }, params = {}) {
    let {
      appointmentList
    } = state;
    let {
      id
    } = params;
    return new Promise((resolve, reject) => {
      let response = {};
      if (id) {
        response = appointmentList.find(item => item.id === id);
      }
      resolve({
        data: response || {},
        msg: '',
        code: '10000'
      });
    });
  },
  UpdateAppointmentDetail({
    commit,
    state
  }, data = []) {
    return new Promise((resolve, reject) => {
      commit(types.UPDATE_APPOINTMENT_DETAIL, data);
      resolve();
    });
  }
};