import Layout from '@views/layout/layout';
const homeRouter = {
  path: '/home',
  component: Layout,
  name: 'Home',
  redirect: '/home/index',
  meta: {
    title: '首页',
    noCache: true,
    requireAuth: true
  },
  children: [{
    path: 'index',
    name: 'HomeIndex',
    components: {
      default: () => import('@views/home/index')
    },
    props: {
      default: route => ({
        status: route.query.status
      })
    },
    meta: {
      title: '首页',
      noCache: true,
      requireAuth: true
      // keepAlive: true
    }
  }]
};

export default homeRouter;