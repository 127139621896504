import _cloneDeep from "lodash/cloneDeep";
import "core-js/modules/es.array.push.js";
import * as types from './mutations_types';
import { cancelAxiosText } from '@config/config';
export default {
  SetLoading: ({
    commit
  }, isLoading) => {
    commit(types.SET_LOADING, isLoading);
  },
  CancelInfoPush({
    commit,
    state
  }, data) {
    let cancelInfo = _cloneDeep(state.cancelInfo);
    cancelInfo.push(data);
    commit(types.SET_CANCEL_INFO, cancelInfo);
  },
  CancelInfoPop({
    commit,
    state
  }) {
    let cancelInfo = _cloneDeep(state.cancelInfo);
    if (cancelInfo.length > 0) cancelInfo.pop().cancel(cancelAxiosText);
    commit(types.SET_CANCEL_INFO, cancelInfo);
  },
  CancelInfoSplice({
    commit,
    state
  }, index) {
    let cancelInfo = _cloneDeep(state.cancelInfo);
    if (cancelInfo.length > 0) cancelInfo.splice(index, 1);
    commit(types.SET_CANCEL_INFO, cancelInfo);
  }
};