var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-card cyui-record-item",
    on: {
      "click": _vm.goDetail
    }
  }, [_c('div', {
    staticClass: "cyui-card-header"
  }, [_c('div', {
    staticClass: "cyui-card-header-line cyui-hairline-bottom"
  }, [_vm.headerContentField.isShow ? _c('div', {
    staticClass: "cyui-card-header-content"
  }, [_c('div', {
    staticClass: "cyui-flexbox"
  }, [_vm.headerContentField.icon ? _c('div', [_c('svg-icon', {
    attrs: {
      "icon-class": `${_vm.headerContentField.icon.svgName}`
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "cyui-flexbox-item cyui-ellipsis",
    style: {
      color: _vm.headerContentField.color
    }
  }, [_vm._v(" " + _vm._s(_vm.headerContentField.title) + " " + _vm._s(_vm.data[_vm.headerContentField.key]) + " ")])])]) : _vm._e(), _vm.headerExtraField.isShow ? _c('div', {
    staticClass: "cyui-card-header-extra",
    style: {
      color: _vm.headerExtraField.color
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.checkOut.apply(null, arguments);
      }
    }
  }, [_vm.headerExtraField.filterMethod ? [_vm._v(" " + _vm._s(_vm.headerExtraField['title']) + " " + _vm._s(_vm.headerExtraField.filterMethod(`${_vm.data[_vm.headerExtraField.key]}`)) + " ")] : [_vm._v(" " + _vm._s(_vm.headerExtraField['title']) + " ")]], 2) : _vm._e()])]), _c('div', {
    staticClass: "cyui-card-body"
  }, [_c('div', {
    staticClass: "cyui-record-info"
  }, [_vm._l(_vm.bodyFields, function (item, index) {
    return [_c('div', {
      key: `bodyFields${index}`
    }, [item.filterMethod ? [_vm._v(" " + _vm._s(item.title) + " " + _vm._s(item.filterMethod(`${_vm.data[item.key]}`) || '-') + " ")] : [_vm._v(" " + _vm._s(item['title']) + " " + _vm._s(_vm.data[item.key] || '-') + " ")]], 2)];
  })], 2)]), _c('div', {
    staticClass: "cyui-card-footer"
  }, [_c('div', {
    staticClass: "cyui-card-footer-line cyui-hairline-top"
  }, [_vm.footerContentField.isShow ? _c('div', {
    staticClass: "cyui-card-footer-content"
  }, [_c('div', {
    staticClass: "cyui-flexbox"
  }, [_c('div', {
    staticClass: "cyui-flexbox-item cyui-ellipsis",
    style: {
      color: _vm.footerContentField.color
    }
  }, [_vm._v(" " + _vm._s(_vm.footerContentField.title) + " ")])])]) : _vm._e(), _vm.footerExtraField.isShow ? _c('div', {
    staticClass: "cyui-card-footer-extra",
    class: {
      'orange-color': _vm.footerExtraField.getColorMethod ? _vm.footerExtraField.getColorMethod(`${_vm.data[_vm.footerExtraField.key]}`) === 'orange' : false,
      'green-color': _vm.footerExtraField.getColorMethod ? _vm.footerExtraField.getColorMethod(`${_vm.data[_vm.footerExtraField.key]}`) === 'green' : false,
      'primary-color': _vm.footerExtraField.getColorMethod ? _vm.footerExtraField.getColorMethod(`${_vm.data[_vm.footerExtraField.key]}`) === 'primary' : false
    },
    style: {
      color: _vm.footerExtraField.color
    }
  }, [_vm.footerExtraField.filterMethod ? [_vm._v(" " + _vm._s(_vm.footerExtraField.filterMethod(`${_vm.data[_vm.footerExtraField.key]}`, `${_vm.data[_vm.footerExtraField.key2]}`) || '-') + " ")] : [_vm._v(" " + _vm._s(_vm.data[_vm.footerExtraField.key] || '-') + " ")]], 2) : _vm._e(), _c('div', {
    staticClass: "cyui-card-footer-arrow cyui-card-footer-arrow-right",
    class: {
      'orange-color': _vm.footerExtraField.getColorMethod ? _vm.footerExtraField.getColorMethod(`${_vm.data[_vm.footerExtraField.key]}`) === 'orange' : false,
      'green-color': _vm.footerExtraField.getColorMethod ? _vm.footerExtraField.getColorMethod(`${_vm.data[_vm.footerExtraField.key]}`) === 'green' : false,
      'primary-color': _vm.footerExtraField.getColorMethod ? _vm.footerExtraField.getColorMethod(`${_vm.data[_vm.footerExtraField.key]}`) === 'primary' : false
    }
  })])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };